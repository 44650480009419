import type {$AxiosXHR, Axios, AxiosPromise} from "axios";
import {axiosInstance} from "../../api";
import type {Cart, CartType} from "../models";
import {BaseCartItem} from "../models";
import type {WatchList} from "../../watchlist/models";

export type CartTransferModel = {
	name: string,
	type: CartType
}

class CartService {
	cartClient: Axios;

	constructor() {
		this.cartClient = axiosInstance;
	}

	async getCart(): Promise<Cart> {
		const response = await this.cartClient.get("/cart/my");
		return response.data;
	}

	async getWatchList(name: string, cartType: CartType): Promise<WatchList> {
		const response: $AxiosXHR<Cart> = await this.cartClient.get(`/cart/my/${cartType}`,
			{params: {cartName: name}}
		);

		return response.data;
	}

	async createCart(name: string, cartType: CartType): Promise<WatchList> {
		const response: $AxiosXHR<Cart> = await this.cartClient.put(
			"/cart/my",
			{
				name,
				cartType
			}
		);

		return response.data;
	}

	async deleteWatchList(name: string): Promise<WatchList> {
		const response: $AxiosXHR<Cart> = await this.cartClient.delete(
			"/cart/my/watchlist",
			{
				data: {
					watchListName: name
				}
			}
		);

		return response.data;
	}

	async removeItem(partNumber: string, cartName: string, cartType: CartType): Promise<void> {
		return await this.cartClient.delete(
			"/cart/my/item",
			{
				data: {
					partNumber,
					cartType,
					name: cartName
				}
			});
	}

	async clear(): Promise<void> {
		return await this.cartClient.delete("/cart/my");
	}

	async addCartItem(partNumber: string, amount: string, note: string, cartName: string, cartType: CartType): Promise<void> {
		return await this.cartClient.put(
			"/cart/my/item",
			{
				partNumber,
				amount,
				note,
				cartType,
				name: cartName
			}
		);
	}

	async addCartItems(cartName: string, cartType: CartType, items: BaseCartItem): Promise<void> {
		return await this.cartClient.put(
			"/cart/my/items",
			{
				cartType,
				name: cartName,
				items
			}
		);
	}

	async editItem(partNumber: string, recipient: string, note: string, amount: string, expressOrder: boolean, cartName: string, cartType: CartType): Promise<void> {
		return await this.cartClient.patch(
			"/cart/my/item",
			{
				partNumber,
				note,
				name: cartName,
				amount,
				expressOrder,
				recipient,
				cartType
			}
		);
	}

	async transferItems(from: CartTransferModel, to: CartTransferModel, partNumbers: string[]): Promise<void> {
		return await this.cartClient.post(
			"/cart/my/transfer",
			{
				from,
				to,
				partNumbers
			}
		);
	}

	getCartDocument = (cartName: string, download: boolean): AxiosPromise => {
		return this.cartClient.get("/cart/my/document",
			{
				params: {
					cartName: cartName,
					download: download
				},
				responseType: "arraybuffer"
			});
	};

	exportWatchList = (watchListName: string): AxiosPromise => {
		return this.cartClient.get("/cart/my/watchlist/export",
			{
				params: {
					cartName: watchListName
				},
				responseType: "arraybuffer"
			});
	};
}

const CartServiceInstance = new CartService();
export default CartServiceInstance;
